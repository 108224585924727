import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    marginBottom: theme.spacing(1),
    marginTop: "-.5em"
  },
  selectEmpty: {
    marginTop: theme.spacing(0)
  }
}));

export const QuestionToggle = () => {
  const classes = useStyles();
  const [age, setAge] = React.useState("All");

  const handleChange = event => {
    setAge(event.target.value);
  };

  return (
    <div>
      <FormControl
        margin="dense"
        className={classes.formControl}
        variant="outlined"
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          dense
          onChange={handleChange}
          style={{
            width: "10em",
            textAlign: "center",
            fontSize: "0.8rem"
          }}
        >
          <MenuItem style={{ fontSize: ".8rem" }} dense value={"All"}>
            All Questions
          </MenuItem>
          <MenuItem style={{ fontSize: ".8rem" }} dense value={"Technical"}>
            Technical
          </MenuItem>
          <MenuItem style={{ fontSize: ".8rem" }} dense value={"Behavioral"}>
            Behavioral
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};
